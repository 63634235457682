import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import { round } from "utils";

const { persistAtom } = recoilPersist();
export const productsState = atom({
  key: "productsState",
  default: {},
});

export const productByOrderState = atom({
  key: "productByOrderState",
  default: [],
});

export const productNameFilterState = atom({
  key: "productNameFilterState",
  default: "",
});

export const productSortFilterState = atom({
  key: "productSortFilterState",
  default: {
    sort: "DESC",
  },
});

export const productPaginationState = atom({
  key: "productPaginationState",
  default: {
    total: 0,
    count: 0,
    skip: 0,
    limit: 0,
  },
});

export const cartState = atom({
  key: "cartState",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const cartTotalState = selector({
  key: "cartTotalState",
  get: ({ get }) => {
    const cart = get(cartState);

    const total = Object.values(cart).reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);

    return round(total, 2);
  },
});

export const popularProductsState = atom({
  key: "popularProductsState",
  default: {},
});

export const favoriteProdutsState = atom({
  key: "favoriteProdutState",
  default: {},
});

export default {
  productsState,
  productPaginationState,
  productNameFilterState,
  popularProductsState,
  favoriteProdutsState,
  productSortFilterState,
};
