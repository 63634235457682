import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Image
} from "react-bootstrap";
import { BAKERY_EDGE_LOGO, BAKERY_LAKE_LOGO, BAVARIAN_LOGO, DISTRUPT_LOGO, DIYATHA_STATION_LOGO, FIFTYKAY_LOGO, FOOTER_LOGO, KOBE_LOGO, LONG_BAR_LOGO, PRANZO_LOGO, THUNAPAHA_LOGO } from "assets/img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faFacebookSquare, faInstagram, faInstagramSquare, faYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <>
      {/* <section className="section pt-2 pb-2 text-center bg-white"> */}
        {/* <Container> */}

        {/* <Image style={{maxWidth: 150}} className="img-fluid" src="/img/logo.png" alt="WE logo" /> */}
          {/* <Row> */}
            {/* <Col sm={12}>
              <h5 className="m-0">
              Hotline { " "}
                <Link to="login">+94 70 600 3344</Link>
              </h5>
            </Col> */}
          {/* </Row>
        </Container>
      </section> */}
      {/* <footer className="pb-4 text-center bg-white"> */}
        {/* <Container> */}
          {/* <p>
            <Link to="/terms-conditions">Terms & Conditions</Link>&#160;|&#160;
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p> */}
          {/* <p className="mt-0 mb-0">
            © 2023 <a href="http://www.vision8.io/">VISION8</a>. All Rights
            Reserved.
          </p> */}

        {/* </Container> */}
      {/* </footer> */}
      <footer className="text-white py-4" style={{backgroundColor: "#0b0a06"}}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-3">
          <hr style={{ border: '0', height: '2px', backgroundColor: '#211a12', margin: '20px 0' }} />
          <h5 className="fGold">For any inquiry please contact +94 11 286 3863</h5>
          <hr style={{ border: '0', height: '2px', backgroundColor: '#211a12', margin: '20px 0' }} />
          </div>
        </div>
        <div className="row mb-4">
          {/* Partner Logos */}
          <div className="col-12 text-center mb-3">
  <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
    <div className="col-2 col-md-1 mb-2">
      <img
        src={BAKERY_LAKE_LOGO}
        alt="Partner 1"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={BAKERY_EDGE_LOGO}
        alt="Partner 2"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={BAVARIAN_LOGO}
        alt="Partner 3"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={DISTRUPT_LOGO}
        alt="Partner 4"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={DIYATHA_STATION_LOGO}
        alt="Partner 5"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={FIFTYKAY_LOGO}
        alt="Partner 6"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={KOBE_LOGO}
        alt="Partner 7"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={PRANZO_LOGO}
        alt="Partner 8"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={LONG_BAR_LOGO}
        alt="Partner 9"
        className="partner-logo w-100"
      />
    </div>
    <div className="col-3 col-md-1 mb-2">
      <img
        src={THUNAPAHA_LOGO}
        alt="Partner 10"
        className="partner-logo w-100"
      />
    </div>
  </div>
</div>

        </div>
        <hr style={{ border: '0', height: '2px', backgroundColor: '#211a12', margin: '20px 0' }} />
        <div className="row align-items-center">
          {/* Logo and Contact Details */}
          <div className="col-md-2 text-center">
            <img
              src={FOOTER_LOGO}
              alt="Logo"
              className="mb-3 img-fluid"
            />
            
          </div>

          {/* Resturents details */}
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-6 col-lg-3 mb-3 fAd">
                <p className="fAdHeading"><strong>ADDRESS</strong></p>
                <hr className="fHr mt-1 mb-2 mx-0" />
                <p className="pt-2">316, Ethul Kotte Road, </p>
                <p>Battaramulla, Sri Lanka</p>
              </div>
              <div className="col-6 col-lg-3 mb-3 fAd">
                <p className="fAdHeading"><strong>GENERAL INQUIRIES</strong></p>
                <hr className="fHr" />
                <p className="pt-2">+94 11 286 3863 </p>
                <p>we@watersedge.lk</p>
                <p className="d-none">aaaaaaa</p>
              </div>
              <div className="col-6 col-lg-3 mb-3 fAd">
                <p className="fAdHeading"><strong>RESTAURANT RESERVATIONS</strong></p>
                <hr className="fHr" />
                <p className="pt-2">+94 11 286 3863</p>
                <p>reservations@watersedge.lk</p>
                <p></p>
              </div>
              <div className="col-6 col-lg-3 mb-3 fAd">
                <p className="fAdHeading"><strong>BANQUET SALES</strong></p>
                <hr className="fHr" />
                <p className="pt-2">+94 70 600 3322</p>
                <p>udayamali@watersedge.lk</p>
                <p></p>
              </div>
            </div>
          </div>
          {/* Social Media Icons */}
          {/* <div className="col-md-2 mb-3 text-md-end text-center">
            <h5 className="mb-4">Follow Us</h5>
            <div className="d-flex justify-content-md-end justify-content-center align-items-center">
              <a
                href="https://www.facebook.com/WatersEdgeSL/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" style={{ color: '#3b5998' }} />
              </a>
              <a
                href="https://www.youtube.com/channel/UCIeMFV1zD57ICZyzDsRsOlA"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faYoutubeSquare} size="2x" style={{ color: '#FF0000' }} />

              </a>
              <a
                href="https://www.instagram.com/watersedgesl/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faInstagramSquare} size="2x" style={{ color: '#C13584' }} />

              </a>
            </div>
          </div> */}
        </div>
        <hr style={{ border: '0', height: '2px', backgroundColor: '#211a12', margin: '20px 0' }} />
        <div className="row">
          <div className="col-12 col-md-6 mt-2 fAd d-flex justify-content-md-start justify-content-center align-items-center">
            <p>&copy; {new Date().getFullYear()} Waters Edge. All rights reserved.</p>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-md-end justify-content-center align-items-center">
              <a
                href="https://www.facebook.com/WatersEdgeSL/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" className="fAd" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCIeMFV1zD57ICZyzDsRsOlA"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" className="fAd" />

              </a>
              <a
                href="https://www.instagram.com/watersedgesl/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mx-2"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" className="fAd" />

              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

Footer.propTypes = {
  sectionclassName: PropTypes.string,
  popularCHclassName: PropTypes.string,
  popularCountries: PropTypes.array,
  popularFHclassName: PropTypes.string,
  popularFood: PropTypes.array,
  copyrightText: PropTypes.string,
  madewithIconclassName: PropTypes.string,
  firstLinkText: PropTypes.string,
  firstLink: PropTypes.string,
  secondLinkText: PropTypes.string,
  secondLink: PropTypes.string,
};

Footer.defaultProps = {
  sectionclassName: "footer-bottom-search pt-5 pb-5 bg-white",
  popularCHclassName: "text-black",
  popularCountries: [],
  popularFHclassName: "mt-4 text-black",
  popularFood: [],
  copyrightText: "© 2021-2022 VISION8. All Rights Reserved",
  madewithIconclassName: "heart heart-icon text-danger",
  firstLinkText: "Gurdeep Osahan",
  firstLink: "//www.instagram.com/iamgurdeeposahan/",
  secondLinkText: "Askbootstrap",
  secondLink: "//askbootstrap.com/",
};

export default Footer;
