import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const userState = atom({
  key: "userState",
  default: {
    id: "",
    name: "",
    email: "",
    phone: "",
    image: "",
    token: "",
    updatedAt: "",
    createdAt: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const authState = selector({
  key: "authState",
  get: ({ get }) => {
    const user = get(userState);

    if (user && user.token.length > 0) {
      return true;
    }

    return false;
  },
});

export const locationsState = atom({
  key: "locationsState",
  default: {},
});

export const userCardsState = atom({
  key: "userCardsState",
  default: [],
});

export const userPromocodesState = atom({
  key: "userPromocodesState",
  default: [],
});

export default {
  userState,
  authState,
  locationsState,
  userPromocodesState,
};
