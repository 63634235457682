import React from "react";
import PropTypes from "prop-types";
import Icofont from "react-icofont";

const CartDropdownItem = ({
  id = "",
  onRemove,
  price = "",
  title = "",
  optionName = "",
  potionName = "",
}) => {
  const handleRemoveItem = () => {
    onRemove(id);
  };

  const renderRemove = () => {
    if (onRemove && typeof onRemove === "function") {
      return (
        <Icofont
          icon={"close"}
          className="mr-2"
          onClick={handleRemoveItem}
          style={{ fontSize: 18 }}
        />
      );
    }
    return null;
  };

  return (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="mb-2"
    >
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {renderRemove()}

          <div className="text-secondary">
            <div>{title}</div>
            <div>
              ({optionName} {potionName})
            </div>
          </div>
        </div>
      </div>
      <div className="text-secondary">{price}</div>
    </div>
  );
};

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.any.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired,
};

CartDropdownItem.defaultProps = {
  title: "",
  price: "",
  icoIcon: "",
  iconclass: "",
};

export default CartDropdownItem;
