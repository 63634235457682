import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { LOGO } from "assets/img";
import { RecoilRoot } from "recoil";
import "./index.css";
import RecoilNexus from 'recoil-nexus'

import * as serviceWorker from "./serviceWorker";

const Fallback = () => {
  return (
    <div style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}>
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={LOGO} alt="logo" width="150px" />
      </div>
    </div>
  );
};

ReactDOM.render(
  <RecoilRoot>
     <RecoilNexus/>
    <Suspense fallback={<Fallback />}>
      <Router>
        <Route path="/" component={App} />
      </Router>
    </Suspense>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
