import { atom } from "recoil";

export const bannersListState = atom({
  key: "bannersListState",
  default: [],
});

export const termsListState = atom({
  key: "termsListState",
  default: [],
});

export const deliveryAreasListState = atom({
  key: "deliveryAreasListState",
  default: [],
});

export const freeDeliveryAreasListState = atom({
  key: "freeDeliveryAreasListState",
  default: [],
});

export const settingsListState = atom({
  key: "settingsListState",
  default: [],
});

export default {
  bannersListState,
  deliveryAreasListState,
  freeDeliveryAreasListState,
  settingsListState,
};
