import React from "react";
import { Modal } from "react-bootstrap";
import { useModalStore } from "store";

const ModalContainer = () => {
  const { component, props, show, hideModal } = useModalStore((state) => state);

  if (!component) {
    return null;
  }

  return (
    <Modal show={show} onHide={hideModal} {...props}>
      {React.cloneElement(component, { onHide: hideModal })}
    </Modal>
  );
};

export default ModalContainer;
