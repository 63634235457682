import menu1 from "./menu1.jpeg";
import menu2 from "./menu2.jpeg";
import menu3 from "./menu3.jpeg";
import menu4 from "./menu4.jpeg";
export default {
  m1: menu1,
  m2: menu2,
  m3: menu3,
  m4: menu4,
};
