import apiHandler from "./apiHandler";

export const getEventDetails = async (id) => {
  try {
    const response = await apiHandler.get(`events/${id}`);
    return response.data.data
      ? response.data.data
      : [];
  } catch (error) {
    return null;
  }
};

export const getDeliveryTimeSlots = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "DESC",
  }
) => {
  try {
    const response = await apiHandler.post("delivery-time-slots/search", data);
    return response.data.data.results.length > 0
      ? response.data.data.results[0]["time_slots"]
      : [];
  } catch (error) {
    return null;
  }
};

export const createOrder = async (data) => {
  try {
    console.log(data)
    const response = await apiHandler.post("bookings/create", data);
    return response.data.data;
  } catch (error) {
    return null;
  }
};

export const getAllOrders = async (
  data = {
    filter: [],
    limit: 100,
    skip: 0,
    sort: "ASC",
  }
) => {
  try {
    const response = await apiHandler.post("orders/search", data);

    const pagination = response.data.data.pagination;

    const orders = response.data.data.results.reduce((obj, item) => {
      obj[item.id] = {
        ...item,
        items: item.items.reduce((products, item) => {
          const productName = item.product.name;

          return `${products}, ${productName}`;
        }, ""),
      };

      return obj;
    }, {});
    return [orders, pagination];
  } catch (error) {
    return null;
  }
};

export const getOrderById = async (orderId = "") => {
  try {
    const response = await apiHandler.get(`orders/${orderId}`);
    const order = response.data.data;
    return {
      [order.id]: order,
    };
  } catch (error) {
    return null;
  }
};

export const getBookingById = async (orderId = "") => {
  try {
    const response = await apiHandler.get(`bookings/ord/${orderId}`);
    const order = response.data.data;
    return order;
  } catch (error) {
    return null;
  }
};

export default {
  getAllOrders,
  getOrderById,
  createOrder,
  getDeliveryTimeSlots,
  getEventDetails,
  getBookingById
};
