import { devtools } from "zustand/middleware";
import create from "zustand";

export const useModalStore = create(
  devtools((set) => ({
    show: false,
    component: null,
    props: {},
    showModal: (component, props = {}) =>
      set((state) => ({ show: true, component, props })),
    hideModal: () =>
      set((state) => ({ show: false, component: null, props: {} })),
  }))
);

export { default as metaStore } from "./meta";
export { default as productStore } from "./product";
export { default as subCategoryStore } from "./subCategory";
export { default as categoryStore } from "./category";
export { default as userStore } from "./user";
export { default as orderStore } from "./order";
