import { FOOTER_LOGO } from "assets/img";
import React from "react";
import { Image, Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="spinning-line">
        <Image src={FOOTER_LOGO} alt="Loading Logo" className="logo" style={{ height: 65 }} />
      </div>
    </div>
  );
};

export default Loading;
